._mobile{
  display: block;
  @media(min-width: $laptop){
      display: none;
  }
}

._widescreen{
  display: none;
  @media(min-width: $laptop){
      display: block;
  }
}

._clearfix{
  clear: both;
}

._gold{
  color: $themeColor;
}