html{
    scroll-behavior: smooth;
}

body{
    font-family: $font;
    border: 11px solid $dark;
    color: $dark;
}

._heading-1{
    font-family: $themeFont;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
}

._button{
    background-color: $themeColor;
    border-radius: 100px;
    border: none;
    min-width: 160px;
    text-align: center;
    padding: 12px 20px;
    color: #fff;
    white-space: nowrap;
    display: inline-block;
    font-family: $font;
    cursor: pointer;
}

._socials{
    display: flex;
    column-gap: 10px;
    img{
        width: 40px;
    }
}