footer{
    text-align: center;
    @include topBottomSpacing;
    ._logo{
        width: 172px;
    }
    ._halaal{
        width: 70px;
    }
    ._grid{
        align-items: center;
    }
    ._socials{
        justify-content: center;
    }
}