@mixin transition($speed: 0.25s, $delay: 0s){
  transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition: $speed;
  -o-transition: $speed;
  -moz-transition: $speed;
  -webkit-transition: $speed;
  transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
}

@mixin boxShadow {
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
}

@mixin topBottomSpacing{
  margin-top: 50px;
  margin-bottom: 50px;
  @media(min-width: $tablet){
    margin-top: 75px;
    margin-bottom: 75px;
  }
  @media(min-width: $laptop){
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@mixin topSpacing{
  margin-top: 50px;
  @media(min-width: $tablet){
    margin-top: 75px;
  }
  @media(min-width: $laptop){
    margin-top: 100px;
  }
}

@mixin positionCenter{
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%);
}

@mixin positionCenterX{
  position: absolute;
  left: 50%;
  transform:translateX(-50%);
}

@mixin positionCenterY{
  position: absolute;
  top: 50%;
  transform:translateY(-50%);
}