._top-section{
    ._copy{
        padding: 50px 0;
        text-align: center;
        max-width: 470px;
    }
    ._logo{
        width: 100%;
        max-width: 270px;
    }
    ._image{
        padding-bottom: 90%;
        background-position: center;
        background-size: cover;
    }
    ._heading-1{
        margin-top: 60px;
        margin-bottom: 20px;
    }
    p{
        margin-top: 25px;
        margin-bottom: 30px;
    }
    @media(min-width: $tablet){
        position: relative;
        height: 100vh;
        ._copy{
            padding: 75px 0;
            text-align: left;
        }
        ._image{
            position: absolute;
            padding-bottom: 0;
            right: 0;
            top: 0;
            width: 50%;
            bottom: 0;
        }
    }
    @media(min-width: $laptop){
        ._copy{
            padding: 100px 0;
        }
        ._image{
            width: calc(50% + 50px);
        }
    }
}

._section-2{
    @include topBottomSpacing;
}

._form-section{
    ._button{
        @include topSpacing;
    }
}

._thankyou-section{
    ._copy{
        padding: 50px 0;
        text-align: center;
        max-width: 470px;
    }
    ._logo{
        width: 100%;
        max-width: 270px;
    }
    ._image{
        padding-bottom: 90%;
        background-position: center;
        background-size: cover;
    }
    ._heading-1{
        margin-top: 60px;
        margin-bottom: 20px;
    }
    p{
        margin: 30px 0;
    }
    ._terms{
        text-decoration: underline;
    }
    @media(min-width: $tablet){
        position: relative;
        height: 100vh;
        ._copy{
            padding: 75px 0;
            text-align: left;
        }
        ._image{
            position: absolute;
            padding-bottom: 0;
            right: 0;
            top: 0;
            width: 50%;
            bottom: 0;
        }
    }
    @media(min-width: $laptop){
        ._copy{
            padding: 100px 0;
        }
        ._image{
            width: calc(50% + 50px);
        }
    }
}