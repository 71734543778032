@font-face {
    font-family: "Cooper Lt BT";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("../fonts/CooperLtBTLight.ttf") format("truetype"),
}

@font-face {
    font-family: "Avenir Next";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
}

@font-face {
    font-family: "Avenir Next";
    font-weight: 600;
    font-style: bold;
    font-display: swap;
    src: url("../fonts/AvenirNextLTPro-Bold.otf") format("opentype"),
}

@font-face {
    font-family: "Avenir Next";
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    src: url("../fonts/AvenirNextLTPro-It.otf") format("opentype"),
}

@import "variables";
@import "mixins";
@import "helpers";
@import "global";
@import "grid";
@import "ui";
@import "form";
@import "layout";
@import "pages";
@import "animations";