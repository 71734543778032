$font: 'Avenir Next', sans-serif;
$themeFont: 'Cooper Lt BT', serif;

$tablet: 768px;
$laptop: 1124px;
$desktop: 1300px;

$black: #000;
$dark: #102733;
$light: #0000001A;
$grey: #112733;
$themeColor: #C59D3F;